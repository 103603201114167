<template>
  <div></div>
</template>
<script setup lang="ts">
  import { pageView } from "@lib/eventos";
  import { onMounted } from "@vue/runtime-core";

  onMounted(() => {
    pageView();
  });
</script>
